$break-small: 750px;

$break-smaller: 375px;

$text-color: #000;

$text-color-light: #CFCFCF;
$text-color-lighter: #EAEAEA;

$theme-color: #000;
