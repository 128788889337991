@import "../../constants.scss";

@mixin latest-release-width($property) {
  width: calc(25% - #{$property});

  @media (max-width: $break-small) {
  	width: calc(50% - #{$property});
  }

  @media (max-width: $break-smaller) {
  	width: calc(100% - #{$property});
  }
}

.latest-release-section {
	align-items: center;
	flex-direction: column;
	justify-content: center;

	.section-title {
		max-width: none;
		padding: .6rem 0rem;
		@include latest-release-width(0.75em);
	}

	.release {
		@include latest-release-width(2em);
	}
}
